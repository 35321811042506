.scrollable {
  overflow-y: auto;
  height: 90vh;
  width: auto;
  padding-right: 20px;
}

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dcb223;
}
