.ant-modal .ant-modal-header {
  padding: 16px 24px;
  margin: -20px -24px 20px -24px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #fff;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: rgba(0, 0, 0, 0.4);
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #dcb223;
}

.ant-progress-line {
  margin-bottom: 0;
}

.ant-popover .ant-popover-title {
  margin: -12px -12px 12px -12px;
  padding: 6px 12px 6px 12px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  overflow: hidden;
}

.ant-table .ant-table-row:last-child > td {
  border-bottom: transparent !important;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #375361;
  color: white;
  text-align: center;
  border-bottom: 2px solid #d7a600;
}
.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell:hover {
  background-color: #375361;
  color: #d7a600;
  text-align: center;
  border-bottom: 2px solid #d7a600;
}

span.ant-dropdown-trigger.ant-table-filter-trigger {
  color: white;
}

span.ant-dropdown-trigger.ant-table-filter-trigger:hover {
  color: #d7a600;
}

span.ant-table-column-sorter.ant-table-column-sorter-full {
  color: white;
}

