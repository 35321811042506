.dashboard_icon:hover {
  filter: saturate(100%) brightness(100%)
}

.dashboard_icon {
  filter: saturate(70%);
  color: rgb(165, 165, 165);
}

.dashboard__linked-in-account {
  text-align: center;
  font-size: 0.7rem;
  color: hsl(0, 0%, 70%);
}

.dashboard__email__warning {
  text-align: center;
  font-size: 0.7rem;
  color: rgb(245 158 11);
}

.dashboard__table-details-icon {
  margin-top: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.dot.active {
  background-color: green;
}

.dot.stopped {
  background-color: red;
}

.ant-list {
  margin-top: 1em;
}

.list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
  flex-basis: 10%;
  margin-left: auto;
}

.campaign-link {
  text-align: center;
  flex-grow: 1;
}

.fix-width-10 {
  flex-basis: 10%;
  flex-grow: 0;
  flex-shrink: 0;
}

.scroll-Table {
  overflow-y: auto;
  height: 78.5vh;
  width: auto;
}

.scroll-Table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scroll-Table::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.scroll-Table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dcb223;
}