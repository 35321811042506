@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s;
}

.break-word {
  word-break: normal;
  overflow-wrap: anywhere;
}

.font-inherit {
  font-family: inherit;
}