@keyframes fadeAndSlideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeAndSlideIn {
  animation: fadeAndSlideIn 0.5s ease-in-out;
}