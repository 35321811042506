.preview-text {
  margin: 0;
  padding: 0;
}

.scroll-form {
  overflow-y: scroll;
  max-height: 75vh;
  width: auto;
  padding-right: 20px;
}

.scroll-form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scroll-form::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.scroll-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dcb223;
}

.ant-steps-item-title {
  white-space: nowrap;
}

.progress-bar__item {
  filter: saturate(80%); 
}

.progress-bar__item:first-child {
  border-radius: 5px 0 0 5px;
}

.progress-bar__item:last-child {
  border-radius: 0 5px 5px 0;
}
